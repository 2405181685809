import { Box, Center, Flex, Grid } from '@chakra-ui/react'
import type { FC, PropsWithChildren } from 'react'
import React, {
  createContext,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react'
import {
  Outlet,
  ScrollRestoration,
  useLocation,
  useSearchParams,
} from 'react-router-dom'

import { getApiBaseUrl, getVariable, isProduction } from '@/common/env'
import { isFeatureEnabled } from '@/common/feature'
import { isFeatureEnabledV2 } from '@/common/featureSwiitch'
import { getCostByMark } from '@/common/pmsdk/get-cost-by-mark'
import logPageLoadTime from '@/common/pmsdk/logic/log-page-load-time'
import PerformanceMarkTag from '@/common/pmsdk/performance-mark-tag'
import { pmLog } from '@/common/pmsdk/pm-log'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import Footer from '@/modules/app-layout/Footer'
import Header from '@/modules/app-layout/Header'
import { useCurrencyBindingBlocker } from '@/modules/bind-currency/useCurrencyBindingBlocker'
import SnowBackdrop from '@/modules/christmas-dress-up/snow-backdrop/SnowBackdrop'
import DelayLiveChat from '@/modules/livechat/LiveChat'
import { ReferralCodeProvider } from '@/modules/login/useReferralCode'
import Notification from '@/modules/notification/Notification'
import { useGetHomePageConfigQuery } from '@/services/jsonApi'
import { useCheckValidGame } from '@/utils/hooks'
import useLoader from '@/utils/hooks/useLoader'
import { getConfigLng, getDeviceType, shuffleArray } from '@/utils/tools'

import { useBankruptRechargeBlocker } from '../activity/bankrupt-recharge/useBankruptRechargeBlocker'
import { selectIsShowMoneyRainBanner } from '../activity/money-rain/store/monyRainSlice'
import type { HomePageConfigInClient } from '../app/home-page-config-types'
import { transToHomePageConfigInClient } from '../app/home-page-config-types'
import { afterLoad, initLoad } from '../app/initLoad'
import ErrorBoundary from '../error/ErrorBoundary'
import GlobalModal from '../global-modal/globalModal'
import { openModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import {
  createDrawerOpenSelector,
  DrawerKey,
  gridTransitionEnd,
} from './app-layout-slice'
import { useGetAlldomainListQuery } from './appApi'
import MainContainer from './components/MainContainer'
import {
  BOTTOM_BAR_HEIGHT,
  HEADER_HEIGHT,
  LEFT_DRAWER_WIDTH,
  RIGHT_DRAWER_WIDTH,
} from './constants'
import CustomTopBanner from './CustomTopBanner'
import LeftDrawer from './LeftDrawer'
import NotificationList from './NotificationList'
import QueriesOnAppInit from './QueriesOnAppInit'
import RightDrawer from './RightDrawer'
import Splash from './Splash'
import TabBar from './TabBar'
import TabBarOld from './TabBarOld'
import { TopBanner } from './TopBanner'
import { useAutoOpenModal } from './useAutoOpenModal'
import { useBalanceUpdater } from './useBalanceUpdater'
import { usePageChangeNotification } from './usePageChangeNotification'
import { usePageViewTracker } from './usePageViewTracker'
import { useRedirectWithQuery } from './useRedirectWithQuery'
import { useRegionLimitGuard } from './useRegionLimitGuard'

const DEVICE = getDeviceType()

function parseQueryString(queryString: string) {
  const params: Record<string, any> = {}
  const pairs = queryString.split('&')

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    const key = decodeURIComponent(pair[0])
    const value = decodeURIComponent(pair[1] || '')

    params[key] = value
  }

  return params
  // test
}

export default function AppCore() {
  const [isLoading] = useLoader(initLoad, afterLoad)
  return (
    <>
      <QueriesOnAppInit />
      {isLoading ? (
        <FullScreen>
          <Splash />
        </FullScreen>
      ) : (
        <AppLayout />
      )}
    </>
  )
}

const HOME_PAGE_CONFIG = getVariable('HOME_PAGE_CONFIG')

const newAdminHomePage = isFeatureEnabled('newAdminHomePage')

// LCP上报次数
let lcpPmCount = 0

export const HomePageConfigContext = createContext<
  HomePageConfigInClient | undefined
>(undefined)

const HomePageConfigProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data } = useGetHomePageConfigQuery(undefined, {
    skip: !HOME_PAGE_CONFIG,
  })

  const lng = getConfigLng()

  const homePageConfig = useMemo(
    () => (data ? transToHomePageConfigInClient(data, lng) : undefined),
    [data, lng],
  )

  return (
    <HomePageConfigContext.Provider value={homePageConfig as any}>
      {children}
    </HomePageConfigContext.Provider>
  )
}

function Banner() {
  const excluedRouters = ['/game']
  const location = useLocation()
  const isShow = excluedRouters.every(
    router => !location.pathname.includes(router),
  )
  const isShowMoneyRainBanner = useAppSelector(selectIsShowMoneyRainBanner)
  if (!isShow) return null
  return <>{isShowMoneyRainBanner ? <CustomTopBanner /> : <TopBanner />}</>
  // return <TopBanner />
}

function AppLayout() {
  const location = useLocation()
  const common_domain_detect = isFeatureEnabledV2('common_domain_detect')

  useLayoutEffect(() => {
    const baseUrl = getApiBaseUrl()
    const mode = isProduction()
    if (window.pmSdk) {
      window.pmSdk.init({
        domain: baseUrl,
        mode,
      })
    }
  }, [])

  const pmPageLoadTimeFun = useCallback(() => {
    try {
      const observer = new PerformanceObserver(entryList => {
        const entries = entryList.getEntries() as (PerformanceEntry & {
          url: string
        })[]
        for (const entry of entries) {
          // FCP
          if (entry.name === 'first-contentful-paint') {
            const fcpTime = entry.startTime
            logPageLoadTime(fcpTime, location.pathname, 'fcp')
          }

          // LCP
          if (
            entry.entryType === 'largest-contentful-paint' &&
            lcpPmCount < 1
          ) {
            const lcpTime = entry.startTime
            const url = entry?.url || ''
            logPageLoadTime(lcpTime, location.pathname, 'lcp', url)
            lcpPmCount++
          }
        }
      })
      observer.observe({ type: 'paint', buffered: true })
      observer.observe({ type: 'largest-contentful-paint', buffered: true })
    } catch (e) {
      //
    }
  }, [])

  const { allDomainList } = useGetAlldomainListQuery(null, {
    selectFromResult: result => ({
      ...result,
      allDomainList: result?.data?.data?.list,
    }),
    skip: !common_domain_detect,
  })
  /** 域名检测上报 */
  const pmDomainDetect = useCallback(() => {
    if (!allDomainList) {
      return
    }
    try {
      const detectDomain = async (list: string[]) => {
        for (const domain of list) {
          const startTime = performance.now()
          try {
            const url = new URL(domain)?.origin || domain
            const res = await fetch(url + '/hardcheck.json')
            const _text = await res.json()
            if (_text?.ok === true) {
              pmLog({
                eventName: 'clientmetrics_domain_detect_success',
                payload: {
                  domain: domain,
                  result: 1,
                },
                target: {
                  labels: ['domain'],
                  value: 'result',
                },
              })
            } else {
              pmLog({
                eventName: 'clientmetrics_domain_detect_failure',
                payload: {
                  domain: domain,
                  result: 0,
                  message: 'detect fail or not ok',
                },
                target: {
                  labels: ['domain', 'message'],
                  value: 'result',
                },
              })
            }
            // }
          } catch (error: any) {
            // resourceTiming 错误的时候返回duration是0
            pmLog({
              eventName: 'clientmetrics_domain_detect_failure',
              payload: {
                domain: domain,
                result: 0,
                message: error?.message,
              },
              target: {
                labels: ['domain', 'message'],
                value: 'result',
              },
            })
          }
          //  let res: PerformanceResourceTiming | null = null
          //    const entries = performance.getEntriesByType('resource')
          // 最新的请求在最后，所以从后面开始遍历
          //   let i = entries.length
          // while (--i >= 0) {
          //   const item = entries[i] as PerformanceResourceTiming
          //   if (item.name === domain) {
          //     res = item
          //     break
          //   }
          // }
          // const time =
          //   (res?.domainLookupEnd || 0) - (res?.domainLookupStart || 0)
          pmLog({
            eventName: 'clientmetrics_domain_detect_duration',
            payload: {
              domain: domain,
              time: performance.now() - startTime,
            },
            target: {
              labels: ['domain'],
              value: 'time',
            },
          })
        }
      }

      let detectDelayTimer: string | number | NodeJS.Timeout | undefined
      // 打乱
      const filteredList = shuffleArray(allDomainList)
      // 每次取10%检测
      const size = Math.ceil(allDomainList.length * 0.1)
      const start = async () => {
        let i = 0
        while (i < allDomainList.length) {
          // 开始遍历检查
          await detectDomain(filteredList.slice(i, i + size))

          if (i + size >= allDomainList.length) {
            return
          }

          // 延迟10min继续下一批检查
          await new Promise(resolve => {
            detectDelayTimer = setTimeout(resolve, 10 * 60 * 1000)
          })

          i += size
        }
      }
      start()
      return () => {
        clearTimeout(detectDelayTimer)
      }
    } catch (e) {
      //
    }
  }, [allDomainList])

  /** 页面加载耗时上报 */
  useEffect(() => {
    const cost = getCostByMark(
      PerformanceMarkTag.PageStart,
      PerformanceMarkTag.PageEnd,
    )

    if (cost > 0) {
      pmLog({
        eventName: 'clientmetrics_page_load_time_spent',
        payload: {
          page_url: location.pathname,
          time_spent: cost,
        },
        target: {
          labels: ['page_url'],
          value: 'time_spent',
        },
      })
    }
    pmPageLoadTimeFun()
  }, [])

  /** 域名检测上报 */
  useEffect(() => {
    if (!common_domain_detect) return
    if (allDomainList?.length > 0) {
      //2min后才触发
      const delayDetectTimer = setTimeout(pmDomainDetect, 2 * 60 * 1000)
      return () => {
        clearTimeout(delayDetectTimer)
      }
    }
  }, [allDomainList, pmDomainDetect])
  return (
    <ErrorBoundary>
      <HomePageConfigProvider>
        <DelayLiveChat />
        <ErrorBoundary>
          <ReferralCodeProvider>
            <GlobalModal />
          </ReferralCodeProvider>
        </ErrorBoundary>
        <ScrollRestoration />
        <NotificationList />
        <Box
          position='fixed'
          left='0'
          top='0'
          zIndex='overlay'
          w='full'
          pt='env(safe-area-inset-top)'
          h={`calc(${HEADER_HEIGHT} + env(safe-area-inset-top))`}
          bg='bg.appLayout.header'
        >
          <Header />
        </Box>
        <BodyLayout>
          <LeftDrawer />
          <Box overflowX='hidden' bg='bg.appLayout.midCol'>
            <Banner />

            <Flex bg='bg.appLayout.content' pt='env(safe-area-inset-top)'>
              <SnowBackdrop />
              <MainContainer>
                <Outlet />
              </MainContainer>
            </Flex>
            <Footer aboutCompany='FOOTERTEXT' />
          </Box>
          <RightDrawer>
            <Notification />
          </RightDrawer>
        </BodyLayout>
        <Box
          display={['block', 'none']}
          position='fixed'
          bottom='0'
          left='0'
          w='full'
          h={BOTTOM_BAR_HEIGHT}
          zIndex='overlay'
        >
          {newAdminHomePage ? <TabBar /> : <TabBarOld />}
        </Box>
        <AppEffects />
      </HomePageConfigProvider>
    </ErrorBoundary>
  )
}

function useOpenAddToHomeScreenModalWithParams() {
  const dispatch = useAppDispatch()
  // const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const paramValue = searchParams.get('add_to_home_screen_modal')
  const uValue = searchParams.get('u')

  useEffect(() => {
    let uParam = ''

    try {
      uParam = window.atob(uValue || '')
    } catch (error) {
      uParam = ''
    }
    // 删除 URL 参数
    searchParams.delete('add_to_home_screen_modal')
    searchParams.delete('u')

    if (uParam) {
      const uParamMap = parseQueryString(uParam)
      Object.keys(uParamMap || {})?.forEach((item: string) => {
        searchParams.set(item, uParamMap[item])
      })
    }

    setSearchParams(searchParams)

    if (paramValue && DEVICE === 'ios') {
      dispatch(
        openModal({
          key: GlobalModalKey.AddToHomeScreen,
          data: { position: 'float-center-button' },
        }),
      )
    }
  }, [])
}

function useAutoOpenLoginModal() {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const action = searchParams.get('action')
  useEffect(() => {
    if (action !== 'google_login' && action !== 'fb_login') return
    dispatch(openModal({ key: GlobalModalKey.Login }))
    setSearchParams(prev => {
      prev.delete('action')
      return prev
    })
  }, [action, dispatch, setSearchParams])
}

function CurrencyBindingBlocke() {
  useCurrencyBindingBlocker()
  useBankruptRechargeBlocker()
  useCheckValidGame()
  return null
}
function AppEffects() {
  useRedirectWithQuery()
  usePageChangeNotification()
  useRegionLimitGuard()
  usePageViewTracker()
  useBalanceUpdater()
  useOpenAddToHomeScreenModalWithParams()
  useAutoOpenLoginModal()
  // useLuckyWheel()
  useAutoOpenModal()

  return <CurrencyBindingBlocke />
}

function BodyLayout(props: { children: React.ReactNode }) {
  const dispatch = useAppDispatch()

  const isLeftDrawerOpen = useAppSelector(
    createDrawerOpenSelector(DrawerKey.Left),
  )

  const isRightDrawerOpen = useAppSelector(
    createDrawerOpenSelector(DrawerKey.Right),
  )

  const handleGridTranslationEnd: React.TransitionEventHandler<
    HTMLDivElement
  > = e => {
    e.preventDefault()
    if (e.target === e.currentTarget) {
      dispatch(gridTransitionEnd())
    }
  }

  return (
    <Grid
      paddingTop='64px'
      w='full'
      templateColumns={[
        '0px 1fr 0px',
        `${isLeftDrawerOpen ? LEFT_DRAWER_WIDTH : '0px'} 1fr ${
          isRightDrawerOpen ? RIGHT_DRAWER_WIDTH : '0px'
        }`,
      ]}
      onTransitionEnd={handleGridTranslationEnd}
      transition={['none', 'all 0.3s']}
    >
      {props.children}
    </Grid>
  )
}

const FullScreen: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Center position='fixed' w='100vw' h='100vh'>
      {children}
    </Center>
  )
}
